import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type MetadataProps = {
    title: string;
    description: string;
    lang?: string;
    isNoIndex?: boolean;
    isHomepage?: boolean;
    bodyClass?: string;
    // if needed: https://fettblog.eu/typescript-react-extending-jsx-elements/
    featuredImage?: string;
};

const Metadata: React.FC<MetadataProps> = ({
    title,
    description,
    isHomepage = false,
    lang = "en",
    isNoIndex = false,
    bodyClass = "default",
    // featuredImage = defaultFeaturedImage,
}) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteURL
                    }
                }
            }
        `
    );

    // The site name should come first in the HTML `<title>` only on the
    // homepage.
    const titleTemplate =
        isHomepage === true
            ? `${site.siteMetadata.title} | %s`
            : `%s | ${site.siteMetadata.title}`;

    // Adds robots noindex meta tag to a page if <Metadata isNoIndex={true} />
    const metaRobots = isNoIndex === true ? "noindex" : null;

    // const img = featuredImage || defaultFeaturedImage;
    {
        /* const twitterHandle = site.siteMetadata.social.twitter.username; */
    }

    return (
        <Helmet title={title} titleTemplate={titleTemplate}>
            <html lang={lang} />
            <body className={bodyClass} />
            <meta name="description" content={description} />

            {/* Override to remove the version number */}
            <meta name="generator" content="Gatsby" />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:image" content={img} /> */}

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/* <meta name="twitter:image" content={img} /> */}
            <meta name="twitter:card" content="summary_large_image" />
            {/* <meta name="twitter:site" content={twitterHandle} /> */}

            {metaRobots ? <meta name="robots" content={metaRobots} /> : null}
        </Helmet>
    );
};

export default Metadata;
